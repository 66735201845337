// Translated
// Migrated
<template>
  <div
    id="charterWindowGuests"
    ref="charterWindowGuests"
    key="charterWindowGuests"
    class="charter-search__window right-0"
  >
    <div class="charter-search__window-content padded overflow-auto">
      <PackageSearchBarWindowHeader
        class="bg-light-turquoise pl-6 py-4 mb-4 -mx-3 sm:-mx-4  sm:-mx-4 lg:mt-0 lg:mx-0"
        @close="clear"
      >
        <template #default>
          <span data-i18n="charterSelectGuests">
            {{ $t('charterSelectGuests') }}
          </span>
        </template>
        <template #content>
          <span
            v-if="noOfPassengers === 9"
            class="ml-2 badge badge-md"
            style="white-space: pre-line;"
          >
            <fa :icon="icons.faInfoCircle" />
            <span data-i18n="charterMaxPassengers">
              {{ $t('charterMaxPassengers') }}
            </span>
          </span>
        </template>
      </PackageSearchBarWindowHeader>
      <div class="flex flex-wrap -mx-4 -mt-3">
        <div
          v-for="(room, index) in rooms"
          :key="`r${index}`"
          class="w-12/12 lg:w-6/12 xl:w-4/12 px-4 mt-4"
        >
          <div class="bg-light-turquoise p-4">
            <div class="flex justify-between mb-4">
              <span
                class="text-lg font-medium"
                data-i18n="charterSearchRoomTitle"
              >{{ $t('charterSearchRoomTitle', index + 1) }}</span>
              <button
                v-if="index > 0"
                class="p-0 bg-transparent text-dark hover:text-dark-gray"
                type="button"
                data-i18n="charterSearchRemoveRoom"
                @click="removeRoom(index)"
              >
                <fa
                  icon="circle-xmark"
                  size="2xl"
                />
              </button>
            </div>
            <div class="flex justify-between mb-2">
              <div>
                <span
                  class="text-base font-medium"
                  data-i18n="charterSearchAdults"
                >{{ $t('charterSearchAdults') }}</span>
                <div
                  class="charter-search__btn-title"
                  data-i18n="charterSearchAdultsSub"
                >
                  {{ $t('charterSearchAdultsSub') }}
                </div>
              </div>
              <div class="flex">
                <NumberButtonInput
                  :value="room.Adults"
                  :disable-increase="passengersFull"
                  :min="1"
                  data-test-adult-number-input
                  @on-increase="addAdult(index)"
                  @on-decrease="removeAdult(index)"
                />
              </div>
            </div>
            <div class="flex justify-between">
              <div>
                <span
                  class="text-base font-medium"
                  data-i18n="charterSearchChildren"
                >{{ $t('charterSearchChildren') }}</span>
                <div
                  class="charter-search__btn-title"
                  data-i18n="charterSearchChildrenSub"
                >
                  {{ $t('charterSearchChildrenSub') }}
                </div>
              </div>
              <div class="flex">
                <NumberButtonInput
                  :value="room.Children"
                  :disable-increase="passengersFull"
                  :min="0"
                  data-test-child-number-input
                  @on-increase="addChild(index)"
                  @on-decrease="removeChild(index)"
                />
              </div>
            </div>
            <div
              v-if="room.Children > 0"
              class="border-t pt-4 mt-4"
            >
              <div
                v-for="(_, cindex) in room.ChildAges"
                :key="`c_${index}_${cindex}`"
                class="charter-search__child-age"
              >
                <label
                  class="mb-0"
                  :for="`c_${index}_${cindex}`"
                  data-i18n="charterSearchChildAge"
                >{{ $t('charterSearchChildAge', cindex + 1) }}</label>
                <div class="charter-search__custom-select charter-search__window-guests__child-age">
                  <select
                    :id="`c_${index}_${cindex}`"
                    class="custom-select"
                    :value="room.ChildAges[cindex]"
                    @change="($event) => updateChildAge(index, cindex, +$event.target.value)"
                  >
                    <option
                      v-for="r in ageArray"
                      :key="r"
                      :value="r"
                    >
                      {{ r }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-12/12 lg:w-6/12 xl:w-4/12 px-4 mt-4">
          <button
            class="flex items-center text-lg justify-between bg-light-turquoise px-6 py-2 w-full"
            type="button"
            :disabled="passengersFull"
            data-test-add-room
            @click="addRoom"
          >
            <span
              class="font-medium"
              data-i18n="charterSearchAddRoom"
            >{{ $t('charterSearchAddRoom') }}</span>
            <fa
              class="text-vivid-turquoise"
              icon="plus-circle"
              size="2xl"
            />
          </button>
        </div>
      </div>
    </div>
    <div class="charter-search__btn-search charter-search__fixed-bottom block lg:!hidden">
      <ScreenWidthProvider v-slot="{ isNarrower }">
        <div
          v-if="(selectChildAges || emptyAgeValues) && isNarrower('lg')"
          class="btn-orange-transparent p-1 legend-text blink charter-search__error__childs-ages"
          data-i18n="charterSelectChildAges"
        >
          {{ $t('charterSelectChildAges') }}
        </div>
      </ScreenWidthProvider>
      <button
        class="rounded-box btn-orange"
        type="button"
        data-i18n="searchButtonText"
        @click="search"
      >
        {{ $t('searchButtonText') }}
      </button>
    </div>
  </div>
</template>

<script>
import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons'

export default defineNuxtComponent({
  setup () {
    const charterWindowGuests = ref(null)

    // defineExpose({ charterWindowGuests })

    return {
      charterWindowGuests,
    }
  },

  props: {
    rooms: {
      type: Array,
      default: () => [],
    },

    passengersFull: {
      type: Boolean,
      default: false,
    },

    selectChildAges: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'add-adult',
    'add-child',
    'add-room',
    'clear',
    'remove-adult',
    'remove-child',
    'remove-room',
    'search',
    'update',
    'update-child-age',
  ],

  data () {
    return {
      icons: {
        faInfoCircle,
      },

      ageArray: [...Array(18).keys()],
      emptyAgeValues: false,
    }
  },

  computed: {
    displayRooms () {
      return evaluatePeoplePerRoom(this.rooms)
    },

    noOfPassengers () {
      return this.displayRooms.adults + this.displayRooms.children
    },
  },

  created () {
    this.init()
  },

  methods: {
    init () {
      if ((this.rooms || []).length > 0) {
        const rooms = this.rooms.slice()
        rooms.forEach((room, idx) => {
          if (room?.Children > (room?.ChildAges || []).length) {
            rooms[idx] = {
              ...room,
              Children: room.ChildAges.length,
            }
            this.update(rooms)
          }
        })
      }
    },

    clear () {
      this.$emit('clear')
    },

    addRoom () {
      const newRoom = {
        Adults: 1,
        Children: 0,
        ChildAges: [],
      }
      this.$emit('add-room', newRoom)
      this.update([...this.rooms, newRoom])
    },

    removeRoom (index) {
      this.$emit('remove-room', index)
      this.update(this.rooms.filter((_, i) => i !== index))
    },

    addAdult (index) {
      const rooms = this.rooms.slice()
      rooms[index] = {
        ...this.rooms[index],
        Adults: +this.rooms[index].Adults + 1,
      }
      this.$emit('add-adult', index)
      this.update(rooms)
    },

    removeAdult (index) {
      const rooms = this.rooms.slice()
      rooms[index] = {
        ...this.rooms[index],
        Adults: +this.rooms[index].Adults - 1,
      }
      this.$emit('remove-adult', index)
      this.update(rooms)
    },

    addChild (index) {
      const rooms = this.rooms.slice()
      rooms[index] = {
        ...this.rooms[index],
        Children: +this.rooms[index].Children + 1,
        ChildAges: [...this.rooms[index].ChildAges, null],
      }

      this.$emit('add-child', index)
      this.update(rooms)
    },

    removeChild (index) {
      const rooms = [...this.rooms]

      rooms[index] = {
        ...rooms[index],
        ChildAges: rooms[index].ChildAges.slice(0, -1),
        Children: +rooms[index].Children - 1,
      }

      this.$emit('remove-child', index)
      this.update(rooms)
    },

    updateChildAge (roomIndex, childIndex, age) {
      const rooms = [...this.rooms]

      const ChildAges = rooms[roomIndex].ChildAges
      ChildAges[childIndex] = age
      rooms[roomIndex] = {
        ...rooms[roomIndex],
        ChildAges,
      }

      this.$emit('update-child-age', { room: roomIndex, child: childIndex })
      this.update(rooms)
    },

    update (rooms) {
      const { infants, adults } = evaluatePeoplePerRoom(rooms)
      this.emptyAgeValues = evaluateRoomsHaveEmptyAgeValues(rooms)
      this.$emit('update', {
        emptyAgeValues: this.emptyAgeValues,
        tooManyInfants: infants > adults,
        rooms,
      })
    },

    search () {
      this.$emit('search')
      this.$emit('clear')
    },
  },
})
</script>

<style lang="scss" scoped>
@import "@layers/web/assets/scss/modules/charter/_charter-forms";
.charter-search__custom-select.charter-search__window-guests__child-age select.custom-select {
  border-right: 1px solid $custom-select-border-color
}
</style>
